import React from 'react';
import { Container, Row, Col } from 'reactstrap';

import img1 from '../../../assets/images/carros/carro1.png';
import img2 from '../../../assets/images/carros/carro2.png';
import img3 from '../../../assets/images/carros/carro3.png';
import img4 from '../../../assets/images/carros/carro4.png';
import img5 from '../../../assets/images/carros/carro5.png';
import img6 from '../../../assets/images/carros/carro6.png';

const Images = () => {
    return (
        <div>
            <div className="spacer" id="imgs-component">
                <Container>
                    <Row className="justify-content-center">
                        <Col md="7" className="text-center">
                            <h1 className="title font-bold">Nossa Frota</h1>
                            {/* <h6 className="subtitle">Conheça nossa frota</h6> */}
                        </Col>
                    </Row>
                </Container>
            </div>
            <Container>
                <Row>
                    <Col lg="4" className="m-b-30">
                        {/* <h4 className="card-title">Image with round corner</h4>
                        <h6 className="card-subtitle"><code>.img-rounded</code></h6> */}
                        <img src={img1} alt="img" className="img-responsive img-rounded" width="200" height="300" />
                        <p className="m-t-15 m-b-0"></p>
                    </Col>
                    <Col lg="4" className="text-center m-b-30">
                        {/* <h4 className="card-title">Image with circle</h4>
                        <h6 className="card-subtitle"><code>.img-circle</code> Make sure the image is square not ractangle</h6> */}
                        <img src={img2} alt="img" className="img-circle" width="390" height="300" />
                        <p className="m-t-15 m-b-0"></p>
                    </Col>
                    <Col lg="4">
                        {/* <h4 className="card-title">Image with Thumbnail</h4>
                        <h6 className="card-subtitle"><code>img-thumbnail</code></h6> */}
                        <img src={img4} alt="img" className="img-responsive img-rounded" width="200" height="300" />
                        <p className="m-t-15 m-b-0"></p>
                    </Col>
                </Row>
                <Row>
                    <Col lg="4" className="m-b-30">
                        {/* <h4 className="card-title">Image with round corner</h4>
                        <h6 className="card-subtitle"><code>.img-rounded</code></h6> */}
                        <img src={img5} alt="img" className="img-responsive img-rounded" width="200" />
                        <p className="m-t-15 m-b-0"></p>
                    </Col>
                    <Col lg="4">
                        {/* <h4 className="card-title">Image with Thumbnail</h4>
                        <h6 className="card-subtitle"><code>img-thumbnail</code></h6> */}
                        <img src={img6} alt="img" className="img-responsive img-rounded" width="200" />
                        <p className="m-t-15 m-b-0"></p>
                    </Col>
                    <Col lg="4">
                        {/* <h4 className="card-title">Image with Thumbnail</h4>
                        <h6 className="card-subtitle"><code>img-thumbnail</code></h6> */}
                        <img src={img3} alt="img" className="img-rounded" width="200" height="277" />
                        <p className="m-t-15 m-b-0"></p>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default Images;
