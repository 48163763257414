import React from "react";
import PropTypes from "prop-types";
import CookieConsent from "react-cookie-consent";

// core components
import Header from "../../components/header/header.jsx";
import HeaderBanner from "../../components/banner/banner.jsx";
import Footer from "../../components/footer/footer.jsx";

// sections for this page
import Buttons from "./sections/buttons.jsx";
import Labels from "./sections/labels.jsx";
import PagePagination from "./sections/pagination.jsx";
import Images from "./sections/images.jsx";
import Breadcrumbs from "./sections/breadcrumbs.jsx";
import Cards from "./sections/cards.jsx";
import Dropdowns from "./sections/dropdowns.jsx";
import PageForm from "./sections/form.jsx";
import PageTable from "./sections/table.jsx";
import Notification from "./sections/notification.jsx";
import TooltipPopover from "./sections/tooltip-popover.jsx";
import Typography from "./sections/typography.jsx";
import JsComponents from "./sections/js-components.jsx";
import CallToAction from "../../components/call-to-action/CallToAction"


const Components = () => {
    return (
        <div id="main-wrapper">
            <Header />
            <div className="page-wrapper">
                <div className="container-fluid">
                    <HeaderBanner />
                    <PageForm />
                    <Images />

                    {/* <Buttons />
                    <Labels />
                    <PagePagination />
                    <Images />
                    <Breadcrumbs />
                    <Cards />
                    <Dropdowns />
                    <PageTable />
                    <Notification />
                    <TooltipPopover />
                    <Typography />
                    <JsComponents />
                    <CallToAction /> */}
                </div>
            </div>
            <Footer />
            <CookieConsent
                location="bottom"
                buttonText="Entendi"
                cookieName="myAwesomeCookieName2"
                style={{ background: "#2B373B", fontWeight: "bold" }}
                buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
                expires={150}>
                Utilizamos cookies para que você tenha a melhor experiência em nosso site. Para saber mais acesse nossa Política de Privacidade.{" "}
                {/* <span style={{ fontSize: "10px" }}>This bit of text is smaller :O</span> */}
            </CookieConsent>
        </div>
    );
}

Components.propTypes = {
    classes: PropTypes.object
};

export default Components;
