import React, { useState } from 'react';
import { Container, Row, Col, Form, FormGroup, Label, Input, Button } from 'reactstrap';
import axios from 'axios'

const urlBaseEnv = process.env.REACT_APP_PUBLIC_API_ENV

const PageForm = () => {
    const [data, setData] = useState({})

    const handleChange = (event) => {
        event.persist()
        setData({
            ...data,
            [event.target.name]: event.target.value,
        })
    }

    const handleSend = async () => {
        try {

            if (!data) {
                throw 'Todos os campos devem ser preenchidos.'
            }

            if (!data.nome) {
                throw 'Nome é obrigatório.'
            }

            if (!data.email) {
                throw 'Email é obrigatório.'
            }

            if (!data.cpf) {
                throw 'CPF é obrigatório.'
            }

            if (!data.telefone) {
                throw 'Telefone é obrigatório.'
            }

            if (!data.endereco) {
                throw 'Endereço é obrigatório.'
            }

            const responseUser = await axios.post(`https://identitytoolkit.googleapis.com/v1/accounts:signInWithPassword?key=AIzaSyBKz-u7Tzr9eQqvioJztq3OLuBEJcqAUAE`,
                {
                    email: "servico@transpryme.com",
                    password: "tp@123456",
                    returnSecureToken: true
                })

            axios.defaults.headers.common.Authorization = `Bearer ${responseUser.data.idToken}`

            const response = await axios.post(`${urlBaseEnv}/clientes`, data)

            delete axios.defaults.headers.common.Authorization

            alert('Cadastrado com sucesso. Obrigado por cadastrar seus contatos. ')

            setData({})

        } catch (error) {
            delete axios.defaults.headers.common.Authorization

            alert(error.response?.data || error)
        }
    }

    return (
        <div>
            <div className="spacer" id="forms-component">
                <Container>
                    <Row className="justify-content-center">
                        <Col md="7" className="text-center">
                            <h1 className="title font-bold">Receba Nossas Promoções</h1>
                            <h6 className="subtitle">Os dados pessoais coletados neste formulário são para envio de promoções oferecidas pela Transpryme</h6>
                        </Col>
                    </Row>
                </Container>
            </div>
            <Container>
                <Row>
                    <Col md="12">
                        <Form className="row">
                            <FormGroup className="col-md-6">
                                <Label htmlFor="nome">Nome Completo*</Label>
                                <Input type="text" className="form-control" name="nome" placeholder="Nome Completo" onChange={handleChange} value={data.nome || ''} required />
                            </FormGroup>
                            <FormGroup className="col-md-6">
                                <Label htmlFor="email">Email*</Label>
                                <Input type="email" className="form-control" name="email" placeholder="Email" onChange={handleChange} value={data.email || ''} required />
                            </FormGroup>
                            <FormGroup className="col-md-6">
                                <Label htmlFor="cpf">CPF*</Label>
                                <Input type="number" className="form-control" name="cpf" placeholder="CPF" onChange={handleChange} value={data.cpf || ''} required />
                            </FormGroup>
                            <FormGroup className="col-md-6">
                                <Label htmlFor="telefone">Telefone*</Label>
                                <Input type="text" className="form-control" name="telefone" placeholder="Telefone" onChange={handleChange} value={data.telefone || ''} required />
                            </FormGroup>
                            <FormGroup className="col-md-12">
                                <Label htmlFor="endereco">Endereço*</Label>
                                <Input type="text" className="form-control" name="endereco" placeholder="Endereço" onChange={handleChange} value={data.endereco || ''} required />
                            </FormGroup>

                            <Col md="12">
                                <Button className="btn btn-success waves-effect waves-light m-r-10" onClick={handleSend}>Enviar</Button>
                            </Col>
                        </Form>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default PageForm;
